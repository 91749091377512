import React from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorBoundary from "./components/Core/ErrorBoundary/ErrorBoundary";
import RouteNotFound from "./components/Core/RouteNotFound/RouteNotFound";

//importamos las pantallas que se veran en la aplicacion
const Login = React.lazy(() => import("./screens/Login/Login"));
const Dashboard = React.lazy(() => import("./screens/Dashboard/Dashboard"));
const Home = React.lazy(() => import("./screens/Home/Home"));
const Products = React.lazy(
  () => import("./screens/Configuration/Products/Products"),
);
const CreateProduct = React.lazy(
  () => import("./screens/Configuration/Products/Create/CreateProduct"),
);

const MyBusiness = React.lazy(() => import("./screens/MyBusiness/MyBusiness"));
const MyVenues = React.lazy(() => import("./screens/MyVenues/MyVenues"));
const SalesOrders = React.lazy(() => import("./screens/Sales/Sales"));
const Cuts = React.lazy(() => import("./screens/Cuts/Cuts"));
const Deposits = React.lazy(
  () => import("./screens/Configuration/Payouts/Payouts"),
);
const Configurations = React.lazy(
  () => import("./screens/Configuration/Configuration"),
);
const RecoveryPassword = React.lazy(
  () => import("./screens/RecoveryPassword/RecoveryPassword"),
);
const SelectBussines = React.lazy(
  () => import("./screens/MyBusiness/MyBusiness"),
);

const Profile = React.lazy(
  () => import("./screens/Configuration/Profile/Profile"),
);
const SalesPoints = React.lazy(
  () => import("./screens/Configuration/SalesPoints/SalesPoints"),
);

const AdminScreen = React.lazy(() => import("./screens/SuperAdmin/SuperAdmin"));

const Events = React.lazy(() => import("./screens/SuperAdmin/Events/Events"));
const CreateEvents = React.lazy(
  () => import("./screens/SuperAdmin/Events/Create/CreateEvent"),
);

const Enclosures = React.lazy(
  () => import("./screens/SuperAdmin/Venues/Venues"),
);

const CreateVenue = React.lazy(
  () => import("./screens/SuperAdmin/Venues/Create/CreateVenue"),
);

const Concessions = React.lazy(
  () => import("./screens/SuperAdmin/Concessions/Concessions"),
);
const CreateConcession = React.lazy(
  () => import("./screens/SuperAdmin/Concessions/Create/CreateConcession"),
);

const UsersList = React.lazy(() => import("./screens/SuperAdmin/Users/Users"));
const CreateUser = React.lazy(
  () => import("./screens/SuperAdmin/Users/Create/CreateUsers"),
);

const ConcessionUsers = React.lazy(
  () => import("./screens/Configuration/Users/Users"),
);

const CreateConcessionUsers = React.lazy(
  () => import("./screens/Configuration/Users/create/CreateConcessionsUsers"),
);

const CreateSalesPoints = React.lazy(
  () => import("./screens/Configuration/SalesPoints/Create/CreateSalesPoints"),
);

const SalesDetail = React.lazy(
  () => import("./screens/Sales/Detail/SaleDetail"),
);

const TopProducts = React.lazy(
  () => import("./screens/TopProducts/TopProducts"),
);

const TopSellers = React.lazy(() => import("./screens/TopSellers/TopSellers"));

const TopConcessions = React.lazy(
  () => import("./screens/TopConcessions/TopConcessions"),
);
const Seasons = React.lazy(
  () => import("./screens/SuperAdmin/Seasons/Seasons"),
);

const CreateSeasons = React.lazy(
  () => import("./screens/SuperAdmin/Seasons/Create/CreateSeason"),
);

const Atms = React.lazy(() => import("./screens/SuperAdmin/Atms/Atms"));
const CreateAtm = React.lazy(
  () => import("./screens/SuperAdmin/Atms/Create/CreateAtm"),
);

const Discounts = React.lazy(
  () => import("./screens/Configuration/Discounts/Discounts"),
);

const CreateDiscounts = React.lazy(
  () => import("./screens/Configuration/Discounts/Create/CreateDiscount"),
);

const Promotions = React.lazy(
  () => import("./screens/Configuration/Promotions/Promotions"),
);

const CreatePromotions = React.lazy(
  () => import("./screens/Configuration/Promotions/Create/CreatePromotion"),
);

const BmResume = React.lazy(
  () => import("./screens/SuperAdmin/BmResume/BmResume"),
);

const AtmsReport = React.lazy(() => import("./screens/AtmsReport/AtmsReports"));
const NfcAdminCards = React.lazy(() => import("./screens/SuperAdmin/NFC/NFC"));
const NfcCards = React.lazy(() => import("./screens/NFC/NfcScreen"));

const NfcDetail = React.lazy(() => import("./screens/NFC/Detail/NfcDetail"));
const NfcDetailAdmin = React.lazy(
  () => import("./screens/SuperAdmin/NFC/Detail/NfcDetail"),
);
const ProfileScreen = React.lazy(
  () => import("./screens/Profile/ProfileScreen"),
);

const ATMDetail = React.lazy(
  () => import("./screens/AtmsReport/Detail/AtmDetail"),
);

const Warehouses = React.lazy(
  () => import("./screens/SuperAdmin/Warehouses/Warehouses"),
);

const WareHouseDetail = React.lazy(
  () => import("./screens/SuperAdmin/Warehouses/Create/CreateWarehouse"),
);

const WarehouseAdmin = React.lazy(
  () => import("./screens/Warehouses/Warehouses"),
);

const WareHouseItems = React.lazy(
  () => import("./screens/Warehouses/WarehouseItems/WarehouseItems"),
);
const CreateWareHouseItem = React.lazy(
  () =>
    import("./screens/Warehouses/WarehouseItems/Create/CreateWarehouseItem"),
);

const WarehouseMovements = React.lazy(
  () => import("./screens/Warehouses/WarehouseMovements/WarehouseMovements"),
);

const Customers = React.lazy(
  () => import("./screens/SuperAdmin/Customers/Customers"),
);

const Recharges = React.lazy(
  () => import("./screens/SuperAdmin/CardsLoads/CardsLoads"),
);

const CustomerDetail = React.lazy(
  () => import("./screens/SuperAdmin/Customers/Detail/CustomerDetail"),
);

const VenueWallets = React.lazy(
  () => import("./screens/VenueWallets/VenueWallets"),
);

const VenueWalletsDetail = React.lazy(
  () => import("./screens/VenueWallets/Detail/VenueWalletDetail"),
);

const CardsList = React.lazy(() => import("./screens/SuperAdmin/Cards/Cards"));

const CardMovements = React.lazy(
  () => import("./screens/SuperAdmin/Cards/Movements/Movements"),
);

const NFCRecharges = React.lazy(
  () => import("./screens/NFCRecharges/NFCRecharges"),
);

const NFCSummaryRecharges = React.lazy(
  () => import("./screens/NFCSummaryRecharges/NFCSummaryRecharges")
);

const QRList = React.lazy(
  () => import("./screens/SuperAdmin/Users/QrList/QrList"),
);

const SuperAdminCuts = React.lazy(
  () => import("./screens/SuperAdmin/Reports/Cuts/SuperAdminCuts"),
);

const HardwareAtm = React.lazy(
  () => import("./screens/SuperAdmin/HardwareAtm/HardwareAtm"),
);

const CreateHardwareAtm = React.lazy(
  () => import("./screens/SuperAdmin/HardwareAtm/Create/CreateHardwareAtm"),
);

//declaramos el router con esas pantallas
const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <RouteNotFound />,
  },
  {
    path: "/recuperarcontra",
    element: <RecoveryPassword />,
  },
  {
    path: "/seleccionarnegocio",
    element: <SelectBussines />,
  },
  {
    path: "/panel",
    element: (
      <ErrorBoundary>
        <Dashboard />
      </ErrorBoundary>
    ),
    children: [
      {
        path: "inicio",
        element: <Home />,
      },
      {
        path: "perfil",
        element: <ProfileScreen />,
      },
      {
        path: "misnegocios",
        element: <MyBusiness />,
      },
      {
        path: "misrecintos",
        element: <MyVenues />,
      },
      {
        path: "wallets",
        element: <VenueWallets />,
      },
      {
        path: "wallets/:uuid",
        element: <VenueWalletsDetail />,
      },
      {
        path: "atms",
        element: <AtmsReport />,
      },
      {
        path: "atms/:uuid",
        element: <ATMDetail />,
      },
      {
        path: "ordenes",
        element: <SalesOrders />,
      },
      {
        path: "ordenes/:uuid",
        element: <SalesDetail />,
      },
      {
        path: "cortes",
        element: <Cuts />,
      },
      {
        path: "productos",
        element: <TopProducts />,
      },
      {
        path: "vendedores",
        element: <TopSellers />,
      },
      {
        path: "concesiones",
        element: <TopConcessions />,
      },
      {
        path: "nfc",
        element: <NfcCards />,
      },
      {
        path: "nfc/:uuid",
        element: <NfcDetail />,
      },
      {
        path: "recargas_nfc",
        element: <NFCRecharges />,
      },
      {
        path: "recargadores",
        element: <NFCSummaryRecharges/>
      },
      {
        path: "inventario",
        element: <WarehouseAdmin />,
      },
      {
        path: "inventario/:uuid_warehouse/articulos",
        element: <WareHouseItems />,
      },
      {
        path: "inventario/:uuid_warehouse/articulos/:uuid",
        element: <CreateWareHouseItem />,
      },
      {
        path: "inventario/:uuid_warehouse/movimientos",
        element: <WarehouseMovements />,
      },
      {
        path: "configuracion",
        element: <Configurations />,
      },
      {
        path: "configuracion/depositos",
        element: <Deposits />,
      },
      {
        path: "configuracion/productos",
        element: <Products />,
      },
      {
        path: "configuracion/productos/:uuid",
        element: <CreateProduct />,
      },
      {
        path: "configuracion/perfil",
        element: <Profile />,
      },
      {
        path: "configuracion/usuarios",
        element: <ConcessionUsers />,
      },
      {
        path: "configuracion/usuarios/:uuid",
        element: <CreateConcessionUsers />,
      },
      {
        path: "configuracion/puntosdeventa",
        element: <SalesPoints />,
      },
      {
        path: "configuracion/puntosdeventa/:uuid",
        element: <CreateSalesPoints />,
      },
      {
        path: "configuracion/descuentos",
        element: <Discounts />,
      },
      {
        path: "configuracion/descuentos/:uuid",
        element: <CreateDiscounts />,
      },
      {
        path: "configuracion/promociones",
        element: <Promotions />,
      },
      {
        path: "configuracion/promociones/:uuid",
        element: <CreatePromotions />,
      },

      {
        path: "superadmin",
        element: <AdminScreen />,
      },
      {
        path: "superadmin/eventos",
        element: <Events />,
      },
      {
        path: "superadmin/eventos/:uuid",
        element: <CreateEvents />,
      },
      {
        path: "superadmin/concesiones",
        element: <Concessions />,
      },
      {
        path: "superadmin/concesiones/:uuid",
        element: <CreateConcession />,
      },
      {
        path: "superadmin/recintos",
        element: <Enclosures />,
      },
      {
        path: "superadmin/recintos/:uuid",
        element: <CreateVenue />,
      },
      {
        path: "superadmin/usuarios",
        element: <UsersList />,
      },
      {
        path: "superadmin/usuarios/:uuid",
        element: <CreateUser />,
      },
      {
        path: "superadmin/usuarios/vendedores",
        element: <QRList />,
      },
      {
        path: "superadmin/temporadas",
        element: <Seasons />,
      },
      {
        path: "superadmin/temporadas/:uuid",
        element: <CreateSeasons />,
      },
      {
        path: "superadmin/atms",
        element: <Atms />,
      },
      {
        path: "superadmin/atms/:uuid",
        element: <CreateAtm />,
      },
      {
        path: "superadmin/resumen",
        element: <BmResume />,
      },
      {
        path: "superadmin/almacenes",
        element: <Warehouses />,
      },
      {
        path: "superadmin/almacenes/:uuid",
        element: <WareHouseDetail />,
      },
      {
        path: "superadmin/clientes",
        element: <Customers />,
      },
      {
        path: "superadmin/saldo",
        element: <Recharges />,
      },
      {
        path: "superadmin/clientes/:uuid",
        element: <CustomerDetail />,
      },
      {
        path: "superadmin/nfc",
        element: <NfcAdminCards />,
      },
      {
        path: "superadmin/nfc/:uuid",
        element: <NfcDetailAdmin />,
      },
      {
        path: "superadmin/tarjetas",
        element: <CardsList />,
      },
      {
        path: "superadmin/tarjetas/:uuid",
        element: <CardMovements />,
      },
      {
        path: "superadmin/cortes",
        element: <SuperAdminCuts />,
      },
      {
        path: "superadmin/cajeros",
        element: <HardwareAtm />,
      },
      {
        path: "superadmin/cajeros/:uuid",
        element: <CreateHardwareAtm />,
      },
    ],
  },
]);

export default function RouterProv() {
  return <RouterProvider router={router} />;
}
